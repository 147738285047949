export const state = {
  neighborhood: undefined, // need to set default neighborhood for non instay guest
  cityGems: {
    category: 'all',
  },
}

export const getters = {
  neighborhood(state) {
    return state.neighborhood
  },
  cityGemsFilters(state) {
    return state.cityGems
  },
  cityGemsCategory(state) {
    return state.cityGems.category
  }
}

export const mutations = {
  SET_NEIGHBORHOOD_FILTER(state, neighborhood) {
    state.neighborhood = neighborhood
  },
  SET_CITYGEMS_FILTER(state, filters) {
    state.cityGems = filters
  },

}

export const actions = {
  async setNeighborhood({ commit }, neighborhood = undefined) {

    commit('SET_NEIGHBORHOOD_FILTER', neighborhood)
  },

  async setCityGemsFilter({ commit }, cityGemsFilter = {
    category: 'all',
  }) {
    commit('SET_CITYGEMS_FILTER', { cityGemsFilter })
  },

  async setCityGemsCategory({ commit }, category) {
    commit('SET_CITYGEMS_FILTER', { ...state.cityGems, category })
  }
}
