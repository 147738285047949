const apiURL =
  import.meta.env[import.meta.env.VITE_API_OVERRIDE] ||
  import.meta.env.VITE_API_URL ||
  `http://${window.location.hostname}:3000`

export const config = {
  appVersion: import.meta.env.PACKAGE_VERSION,
  environment: import.meta.env.MODE || 'development',
  apiURL,
  apiHost: new URL(apiURL).host,
  apiServers: {
    qa: import.meta.env.VITE_API_QA_URL,
    staging: import.meta.env.VITE_API_STAGING_URL,
    development: import.meta.env.VITE_API_DEV_URL,
  },
  apiVersion: import.meta.env.VITE_API_VERSION || 'v1',
  cloudinary: {
    cloudName: import.meta.env.VITE_CLOUDINARY_CLOUD_NAME,
    uploadPreset: import.meta.env.VITE_CLOUDINARY_UPLOAD_PRESET,
  },
  sentryDsn: import.meta.env.VITE_SENTRY_DSN,
  google: {
    mapURL: `https://www.google.com/maps/embed/v1/place?key=${import.meta.env.VITE_GOOGLE_MAP_API_KEY
      }`,
  },
  sectionLBlog: {
    apiURL: 'https://section-l.co/wp-json/wp/v2/posts?_embed',
  },
  featureFlagsPath: '/featureFlags.json',
  sectionl: {
    reservationEmail:
      import.meta.env.VITE_SECTIONL_RESERVATION_EMAIL ||
      'reservation@section-l.co',
  },
  faro: {
    appKey: import.meta.env.VITE_FARO_APP_KEY,
  },
  saunaReservationSystem: {
    apiUrl: import.meta.env.VITE_SAUNA_RESERVATION_SYSTEM_URL,
  },
  cms: {
    apiUrl: import.meta.env.VITE_CMS_URL,
  },
  defaultLocale: 'en',
  // supportedLocales: ['en', 'ja'],
}
