import qs from 'qs'
import { config } from '@/constants'
import store from '@/store'

export function useCms() {
  const baseUrl = config.cms.apiUrl
  const headers = new Headers()
  if (store?.getters['auth/currentUser']?.jwtToken) {
    headers.append('Authorization', store?.getters['auth/currentUser']?.jwtToken)
  }

  const fetchNearbyCityGems = async ({ neighborhoodDocumentId }) => {
    const url = new URL('/api/city-gems', baseUrl)
    const query = qs.stringify(
      {
        filters: {
          neighborhoods: {
            documentId: {
              $eq: neighborhoodDocumentId
            }
          },
          // coverImage: {
          //   $notNull: true
          // },
          // tags: {
          //   $notNull: true
          // }
        },
        populate: {
          coverImage: {
            fields: ['url'],
          }
        },
        pagination: {
          limit: 5
        }
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    );

    url.search = query;

    const response = await fetch(url.toString(), { headers })
    const data = await response.json()

    return data
  }

  const fetchCityGems = async ({ neighborhoodDocumentId }) => {
    const url = new URL('/api/city-gems', baseUrl)
    const query = qs.stringify(
      {
        filters: {
          neighborhoods: {
            documentId: {
              $eq: neighborhoodDocumentId
            }
          },
          // coverImage: {
          //   $notNull: true
          // },
          // tags: {
          //   $notNull: true
          // }
        },
        populate: {
          coverImage: {
            fields: ['url'],
          }
        }
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    );

    url.search = query;

    const response = await fetch(url.toString(), { headers })
    const data = await response.json()

    return data
  }

  const fetchNeighborhoods = async () => {
    const url = new URL('/api/neighborhoods?populate=properties', baseUrl)
    const response = await fetch(url.toString(), { headers }) //handle error somehow
    const { data } = await response.json()
    return data
  }

  return {
    fetchCityGems,
    fetchNearbyCityGems,
    fetchNeighborhoods
  }
}
