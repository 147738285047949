import { useCms } from '@/use/use-cms'

export const state = {
  all: undefined,
  current: undefined,
}

export const getters = {
  all(state) {
    return state.all
  },
  current(state) {
    return state.current
  },
}

export const mutations = {
  SET_ALL_NEIGHBORHOODS(state, neighborhoodsList) {
    state.all = neighborhoodsList
  },
  SET_CURRENT_NEIGHBORHOODS(state, currentNeighborhoods) {
    state.current = currentNeighborhoods
  },
}

export const actions = {
  async fetchAndSetNeighborhoods({ commit, dispatch, rootGetters }) {
    const { fetchNeighborhoods } = useCms()
    const neighborhoodsList = await fetchNeighborhoods()

    commit('SET_ALL_NEIGHBORHOODS', neighborhoodsList)

    const acronym = rootGetters['auth/currentProfile'].property?.acronym
    let currentNeighborhoods
    if (acronym) {
      currentNeighborhoods = neighborhoodsList.filter(neighborhood =>
        neighborhood.properties.find(property => property.acronym === acronym)
      )
      commit('SET_CURRENT_NEIGHBORHOODS', currentNeighborhoods)
    }
    // this should be removed and set in the explore / filters pages in the future
    currentNeighborhoods = currentNeighborhoods // if currentNeighborhoods exist (ie, if theres active booking)
      ? currentNeighborhoods[0] // take the first of the current neighborhood as default
      : neighborhoodsList[0] // else just take the first of the entire neighborhood list
    await dispatch('exploreFilters/setNeighborhood', currentNeighborhoods, {
      root: true,
    })
  },
}
